::selection{
	background-color: #d1d3d4; 
	color: #2d2a2b;
}

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

@font-face {
    font-family: 'malina1regular';
    src: url('../fonts/malina1-webfont.woff2') format('woff2'),
         url('../fonts/malina1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


/**
 * Newsletter
 */

.newsletter{
	padding: 30px 0;
	background: $color-pallete-1;
	color: $light-neutral;
	
	.newsletter-text{
		text-transform: uppercase;
		text-align: right; 
		font-size: 18px;
		line-height: 24px;
		
		@media screen and (max-width: $screen-md){
			margin-bottom: 20px;
			text-align: center;
		}
	}
}

/**
 * About
 */

.about{

	p{
		line-height: 2;
		color: $dark-neutral;
	}

	.about-title{ 
		/*font-weight: 700;*/
		font-style: italic;
		
		span{
			display: block;
			font-size: 4rem;
			line-height: 1;

			.big{
				font-size: 42px;

				img{
					width: 23rem;
					margin-left: 1rem;
				}
			}
			
			@media screen and (max-width: $screen-sm){
				/*font-size: 22px;*/
				/*line-height: 24px;*/
				text-align: center;
			}
			
			@media screen and (min-width: $screen-sm-min){
				+ span{
					/*text-indent: 60px;*/
				}
			}
		}
		
		strong{
			font-size: 5rem;
			/*font-weight: 900;*/
			
			@media screen and (max-width: $screen-sm){
				/*font-size: 28px;*/
				/*line-height: 32px;*/
			}
		}
	}
	
	.about-image{
		text-align: center;

		img{
			max-width: 85%;
			float: left;

			@media screen and (max-width: $screen-sm){
				float: inherit;
			}
		}
	}
}

.about-products{
	img{
		width: 25rem;
		height: 20rem;
	}

	p{
		text-align: center;
		font-weight: 700;
		font-size: 3rem;
	}
}

.about-gallery{
	width: 100%;
	height: 280px;
	
	a{
		position: relative;
		width: 100%;
		height: 100%;
		display: block;
		border-radius: 6px;
		overflow: hidden;
		
		&:after{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			@include background-opacity($color-pallete-2, 0.7);
			transition: all 0.2s ease;
		}

		&:hover, &:focus{
			img{
				transform: scale(1.2);
			}
		}
	}
	
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: all 0.3s ease;
	}
}

/**
 * Product
 */

.product-ajax{
	min-height: 300px;
	
	.product-loading{
		padding-top: 40px;
		font-size: 0;
		text-align: center;
		
		&:before{
			content: url(../img/loading.gif);
		}
	}
}

.product-item{
	position: relative;

	@media screen and (max-width: $screen-md){
		width: 80%;
		margin-left: 10%;
	}
	
	> a{
		width: 100%;
		height: 100%;
		display: block;
		color: $text-color-default;
		
		&:hover, &:focus{
			color: $color-pallete-2;
		}
	} 
	
	.product-item-image{
		width: 100%;
		height: 450px;
		text-align: center;
		overflow: hidden;
		border: 2px solid;
		border-radius: 5px;

		@media screen and (max-width: $screen-md){
			height: 500px;
		}
		
		img{
			position: relative;
			top: 30%;
			transform: translateY(-50%);
			max-width: 100%;
			max-height: 100%;
			display: block;
			margin: 0 auto;			
			margin-top: 2rem;

			@media screen and (max-width: $screen-sm){
				top: 30%;
			}
		}
	}
	
	.product-item-content{
		padding: 5px 3rem;
		text-align: left;
		margin-top: -2rem;

		@media screen and (max-width: $screen-sm){
			margin-top: -9rem;
			padding: 5px 1.5rem;
		}

		@media screen and (max-width: $screen-xs){
			margin-top: -4rem;
			padding: 5px 1.5rem;
		}
		
		.product-item-title{ 
			font-size: 1.4rem;
			text-transform: uppercase;
			
			@media screen and (min-width: $screen-sm-min){
				/*height: 65px;*/ 
				overflow: hidden;
			}
		} 

		.product-item-subtitle{
			font-weight: 700;
			font-size: 2rem;
			color: $color-pallete-3;
			text-transform: uppercase;
			margin-bottom: 2rem;
			height: 5rem;
		}
	}

	.product-item-content-inner{
		margin-top: -3rem;

		@media screen and (max-width: $screen-md){
			margin-top: -1rem;
		}
	}
}

.product{
	.product-view-image{
		position: relative;
		width: 100%;
		height: 450px;
		margin-bottom: 20px;
		border: 1px solid $color-pallete-2;
		border-radius: 10px;
		overflow: hidden;

		/*&:hover{
			img{
				-moz-transform: scale(1.2);
				-webkit-transform: scale(1.2);
				transform: scale(1.2);
				top: 0;
			}
		}*/
		
		img{
			position: relative;
			top: 50%;
			display: block;
			margin: 0 auto;
			transform: translateY(-50%);
			max-width: 100%;
			max-height: 100%;
			transition: 0.7s all ease-in-out;
		}
	}
	
	.product-view-subtitle{
		color: #001aa9;
		font-weight: 700;
		text-transform: uppercase;
		font-size: 20px;
	}

	.product-view-title{
		padding-bottom: 10px;
		margin-bottom: 10px;
		color: $color-pallete-3;
		font-weight: 700;
		text-transform: uppercase;
		font-size: 4rem;
		border-bottom: 3px solid $color-pallete-3;  
	}

	.product-view-title-bottom{
		padding-bottom: 15px;
		margin-bottom: 15px;
		color: $color-pallete-3;
		font-weight: 700;
		text-transform: uppercase;
		font-size: 26px;
		border-bottom: 4px solid $color-grey;
	}
	
	.product-view-description{
		margin-bottom: 15px;
		/*border-bottom: 1px solid $color-pallete-1;*/
		font-size: 18px;
		
		p{
			margin-bottom: 15px;
			color: $dark-neutral;
		}
	}
	
	.product-view-meta{
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid $color-pallete-1;
		color: #626262;
		
		span{
			margin-right: 10px;
			
			+ span{
				&:before{
					content: "| ";
					margin-right: 10px;
				}
			}
		}
	}
}

/**
 * Contact
 */

.post-view{
	position: relative;
	margin-bottom: 50px;
	
	.post-view-head{
		margin-bottom: 40px;
		padding-bottom: 40px;
		border-bottom: 1px solid $color-pallete-2;
		
		.post-view-head-title{
			margin-bottom: 20px;
			font-size: 32px;
			line-height: 36px;
			text-transform: uppercase;
			font-weight: 700;
			
			@media screen and (max-width: $screen-sm){
				font-size: 22px;
				line-height: 26px;
			}
		}
		
		.post-view-head-meta{
			span{
				vertical-align: middle;
				margin-right: 20px;
				
				@media screen and (max-width: $screen-sm){
					display: block;
				}
			}
		}
		
		.post-view-head-date{
			color: #626262;
			text-transform: uppercase;
			font-size: 16px;
			font-weight: 700;
			margin-right: 20px;
		}
		
		.post-view-head-views{
			color: #626262;
			text-transform: lowercase;
			font-size: 14px;
		}
	}
	
	.post-view-body{
		margin-bottom: 40px;
		
		.post-view-body-image{
			margin-bottom: 40px;
			
			img{
				border-radius: 10px;
			}
		}
		
		.post-view-body-content{
			p{
				margin-bottom: 20px;
			}
			
			a{
				color: $color-pallete-2;
			}
			
			ul{
				li{
					list-style-position: inside;
				}
			}
		}
	}
	
	.post-view-footer{
		padding-top: 40px;
		border-top: 1px solid $color-pallete-2;
	}
}

/**
 * Contact
 */

.contact-information{
	padding: 30px 0 10px;
	background: $color-pallete-2;
	color: $light-neutral;
	
	.contact-information-item{
		position: relative;
		height: 65px;
		padding-left: 65px;
		
		@media screen and (max-width: $screen-sm){
			max-width: 250px;
			width: 100%;
			margin: 0 auto;
		}
		
		.contact-information-item-icon{
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			font-size: 50px;
			vertical-align: middle;
		}
		
		.contact-information-item-text{
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			font-size: 16px;
		}
	}
}

.contact{
    .content{
        margin-bottom: 7rem;

        h2{
            color: $color-blue-600;
            font-size: 2.5rem;
            margin-left: 1rem;
            i{
                width: 4rem;

                @media screen and (max-width: $screen-md){
                    width: 3rem;
                }
            }

            @media screen and (max-width: $screen-md){
                font-size: 2.25rem;
                // margin-left: 4rem;
            }
        }
        h3{
            margin-left: 6rem;
            margin-bottom: 5rem;
            
            a{
                font-size: 1.75rem;
                color: $color-secondary-400;

                &:hover, &:focus{
                    color: $color-pallete-1;
                }
                
                @media screen and (max-width: $screen-md){
                    font-size: 1.5rem;
                }
            }

            @media screen and (max-width: $screen-md){
                margin-left: 5rem;
            }
        }

        figure{
            z-index: 9;
            position: relative;
        }

        &::before{ 
            content: "";
            // background: url('../img/blue-panel.png');
            background-color: $color-blue-1000;
            position: absolute;
            top: 0;
            width: 25vw;
            height: 50rem;
            background-repeat: no-repeat;
            z-index: 1;
            right: 15vw;

            @media screen and (max-width: $screen-md){
                width: 0;
                margin-left: 0;
            }
        }
        
    }
}

.linha-carrinho{
	display: table;
    height: 200px;
}

.linha-carrinho-inside{
	padding: 0 10px;
	display: table-cell;
    vertical-align: middle;
}

.row-carrinho{
	border: 1px solid; 
}

.image-carrinho img{
	border-right: 1px solid rgb(49,47,45);
    border-image: linear-gradient(to bottom, rgba(49,47,45,0) 25%,rgba(49,47,45,1) 25%,rgba(49,47,45,1) 75%,rgba(49,47,45,0) 75%);
    border-image-slice: 1;
}

.button-carrinho{
	float: right;
}

.div-btn-cart{
	position: relative;
	width: 50px;
	float: left;
	/*margin-right: 5rem;*/
}

.btn-cart{
	height: 45px;
	width: 45px;
	border: 1px solid $color-pallete-2;
	color: $color-pallete-2;
	background: none;
	font-size: 26px;
	border-radius: 10px;
}

.btn-cart:hover{
	color: #fff;
	background: $color-pallete-2;
}

.btn-cart:hover ~ .btn-cart[data-badge]:after{
	color: $color-pallete-2 !important;
	background: none !important;
}

.btn-cart[data-badge]:after {
   content:attr(data-badge);
   position:absolute;
   top:-5px;
   right:-5px;
   font-size:.7em;
   background:$color-pallete-2;
   color:white;
   width:18px;
   height:18px;
   text-align:center;
   line-height:18px;
   border-radius:50%;
   box-shadow:0 1px 8px #333;
}




.breadcrumb {
	padding: 10px 0 10px 0;
}

.breadcrumb li {
	display: -moz-inline-stack;
	display: inline-block;
	vertical-align: middle;
	zoom: 1;
	*display: inline;
	color: $color-grey;
	font-size: 16px;
	font-size: 1.6rem;
	font-weight: 700;
}

.breadcrumb li + li:before {
	content: "/";
	margin: 0 4px;
}

.breadcrumb a {
	color: inherit; 
}

.breadcrumb a:hover,
.breadcrumb a:focus {
	color: $color-pallete-3;
}

.md-25{
	width: 20% !important;

	@media screen and (max-width: $screen-sm){
		width: auto !important;
	}
}



.row-selos{
	figure{
		height: 18rem;

		@media screen and (max-width: $screen-md){
			height: initial;
			margin-bottom: 2rem;
		}
	}
	div{
		width: 30%;
		float: left; 
		text-align: center;
		margin: 0 1.5%;

		@media screen and (max-width: $screen-md){
			width: 100%;
			margin: 2rem 1.5%;
		}
	}
	p{
		color: $dark-neutral;
	}
}

.row-icons{ 
	color: $color-grey-2;
	/*font-weight: 700;*/
	a{
		color: #6c6c6c;

		&:hover, &:focus{
			text-decoration: underline;
		}
	}
	figure{
		height: 8rem;
	}
	div{
		width: 20%;
		float: left; 
		text-align: center;
		margin: 0 2%;

		@media screen and (max-width: $screen-md){
			width: 35%;
			margin: 1rem 2rem;
		}
	}
}

.half-left{
	width: 49.5%;
	float: left;
	margin-right: 1%;

	@media screen and (max-width: $screen-md){
		width: 100%;

		iframe {
		  	width: 100%;
		}
	}
}

.half-right{
	width: 49.5%;
	float: right;

	@media screen and (max-width: $screen-md){
		width: 100%;
	}
}

.list-clientes{
	li{
		list-style: none;
		float: left;
		width: 14%;

		@media screen and (max-width: $screen-md){
			width: 50%;
		}
	}

}



.equipe{
    background: $color-secondary-200;
    padding: 15rem 0 5rem;
	
	.equipe-box{
		border-radius: 4px;
		color: $dark-neutral;
        // padding: 5rem 10rem;

        .row{
            margin: 4rem 0;
        }

        h2{
            font-size: 1.5rem;
            font-weight: 600;
            color: $color-wood-700;
        }

        hr {
            border: 0;
            clear:both;
            display:block;
            width: 100%;
            background-color: $color-neutral-300;
            height: 1px;
            margin-bottom: 4rem;
        }

        .atuacao{
            display: flex;
            align-items: center;
            margin: 0 10px;

            h2{
                margin-right: 2rem;
            }
        }

        .social{
            div{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            h2{
                margin-right: 1rem;
            }
        }
    
        .title{
            display: flex;
            align-items: baseline;

            h2{
                font-size: 2rem;
                font-weight: 600;
                color: $color-neutral-900;
                margin: 0 1rem 1rem 0;
            }
            h3{
                font-size: 1.4rem;
                // font-weight: 600;
                color: $color-wood-700;
                text-transform: uppercase;
            }
        }

        .content{
            color: $color-blue-900;
        }
        
	}
	
	.equipe-header{
		position: relative;
		padding: 15px 0;
		border-bottom: 1px solid #e6e6e6;
		
		.equipe-title{
			padding: 0 40px 0 15px;
			font-size: 22px;
			font-weight: 700;
		}
	}
	
	.equipe-content{
		padding: 15px;
		font-size: 16px;
		overflow-y: auto;
	}
	
	.equipe-close{
		position: absolute;
		top: 50%;
		right: 15px;
		transform: translateY(-50%);
		background: none;
	}
}