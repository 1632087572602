@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;

1,200..900&display=swap");* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font: inherit;
  vertical-align: baseline;
  line-height: 1.4;
}

html {
  font-size: 62.5%;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Source Sans 3", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #002488;
  text-rendering: optimizelegibility;
  overflow-x: hidden;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

a {
  text-decoration: none;
  border: 0;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

strong,
b {
  font-weight: 700;
}

em {
  font-style: italic;
}

button,
input[type="submit"] {
  cursor: pointer;
  border: 0;
  font-family: "Source Sans 3", sans-serif;
  font-size: 16px;
}

input,
textarea,
select {
  font-family: "Source Sans 3", sans-serif;
  font-size: 16px;
}

input.incorrect,
input.error,
textarea.incorrect,
textarea.error,
select.incorrect,
select.error {
  border-color: red;
}

input.correct,
input.success,
textarea.correct,
textarea.success,
select.correct,
select.success {
  border-color: green;
}

input:invalid,
textarea:invalid,
select:invalid {
  box-shadow: none;
}

input[type="search"] {
  appearance: none;
}

textarea {
  resize: vertical;
}

iframe {
  position: relative;
}

.header,
.main,
.footer {
  width: 100%;
  display: block;
  clear: both;
  overflow-x: hidden;
}

.form-control {
  width: 100%;
  height: 40px;
  display: block;
  padding: 0 20px;
  font-style: italic;
  border: 1px solid #002488;
  border-radius: 6px;
  color: #002488;
  line-height: 40px;
}

.form-control::-webkit-input-placeholder {
  color: #002488;
  opacity: 1;
}

.form-control:-moz-placeholder {
  color: #002488;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #002488;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #002488;
  opacity: 1;
}

textarea.form-control {
  height: auto;
  padding: 20px;
  line-height: 20px;
}

.form-search {
  position: relative;
  width: 200px;
  float: left;
  margin-right: 2%;
}

.form-search.filter {
  width: 100%;
  float: none;
}

.form-search input {
  width: 100%;
  height: 45px;
  padding: 0 35px 0 15px;
  border-radius: 4px;
  border: 1px solid #25657C;
  font-style: italic;
}

.form-search input::-webkit-input-placeholder {
  color: #002488;
  opacity: 1;
}

.form-search input:-moz-placeholder {
  color: #002488;
  opacity: 1;
}

.form-search input::-moz-placeholder {
  color: #002488;
  opacity: 1;
}

.form-search input:-ms-input-placeholder {
  color: #002488;
  opacity: 1;
}

.form-search button {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 100%;
  background: transparent;
  color: #25657C;
  font-size: 22px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.form-newsletter {
  position: relative;
}

.form-newsletter input {
  width: 100%;
  height: 45px;
  display: block;
  padding: 0 140px 0 15px;
  border: 1px solid #25657C;
  background: #25657C;
  font-style: italic;
  color: #fff;
}

.form-newsletter input::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}

.form-newsletter input:-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.form-newsletter input::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.form-newsletter input:-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}

.form-newsletter button {
  background: #25657C;
  color: #25657C;
  text-transform: uppercase;
}

.form-newsletter button:hover,
.form-newsletter button:focus {
  background: #1f5568;
}

.form-organize {
  width: 100%;
}

.form-organize label {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  color: #626262;
}

.form-organize select {
  width: calc(100% - 115px);
  height: 45px;
  padding: 0 15px;
  font-style: italic;
  border: 1px solid #25657C;
  border-radius: 10px;
  color: #626262;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  *zoom: 1;
}

.container:before,
.container:after {
  content: "";
  display: table;
}

.container:after {
  clear: both;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  *zoom: 1;
}

.container-fluid:before,
.container-fluid:after {
  content: "";
  display: table;
}

.container-fluid:after {
  clear: both;
}

.row {
  margin-left: -10px;
  margin-right: -10px;
  *zoom: 1;
}

.row:before,
.row:after {
  content: "";
  display: table;
}

.row:after {
  clear: both;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}

.col-xs-1 {
  width: 8.33333%;
}

.col-xs-2 {
  width: 16.66667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.33333%;
}

.col-xs-5 {
  width: 41.66667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.33333%;
}

.col-xs-8 {
  width: 66.66667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.33333%;
}

.col-xs-11 {
  width: 91.66667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.33333%;
}

.col-xs-pull-2 {
  right: 16.66667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.33333%;
}

.col-xs-pull-5 {
  right: 41.66667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.33333%;
}

.col-xs-pull-8 {
  right: 66.66667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.33333%;
}

.col-xs-pull-11 {
  right: 91.66667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.33333%;
}

.col-xs-push-2 {
  left: 16.66667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.33333%;
}

.col-xs-push-5 {
  left: 41.66667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.33333%;
}

.col-xs-push-8 {
  left: 66.66667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.33333%;
}

.col-xs-push-11 {
  left: 91.66667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-offset-2 {
  margin-left: 16.66667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.33333%;
}

.col-xs-offset-5 {
  margin-left: 41.66667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.33333%;
}

.col-xs-offset-8 {
  margin-left: 66.66667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.33333%;
}

.col-xs-offset-11 {
  margin-left: 91.66667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.modaldefault {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  visibility: hidden;
}

.modaldefault.open {
  visibility: visible;
}

.modaldefault.open .modaldefault-overlay {
  opacity: 1;
}

.modaldefault.open .modaldefault-box {
  transform: translate(-50%, -50%) scale(1);
}

.modaldefault .modaldefault-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  opacity: 0;
  background: #000;
  background: rgba(0,0,0,0.7);
}

.modaldefault .modaldefault-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center center;
  max-width: 550px;
  width: 90%;
  max-height: 90%;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px -15px #000;
  border: 1px solid #e6e6e6;
  color: #000;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.modaldefault .modaldefault-header {
  position: relative;
  padding: 15px 0;
  border-bottom: 1px solid #e6e6e6;
}

.modaldefault .modaldefault-header .modaldefault-title {
  padding: 0 40px 0 15px;
  font-size: 22px;
  font-weight: 700;
}

.modaldefault .modaldefault-content {
  padding: 15px;
  font-size: 16px;
  overflow-y: auto;
}

.modaldefault .modaldefault-close {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  background: none;
}

.modalequipe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: none;
}

.modalequipe.open {
  visibility: visible;
  display: block;
}

.modalequipe.open .modalequipe-overlay {
  opacity: 1;
  display: block;
}

.modalequipe.open .modalequipe-box {
  transform: translate(-50%, -50%) scale(1);
  display: block;
}

.modalequipe .modalequipe-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  opacity: 0;
  background: #000;
  background: rgba(0,0,0,0.7);
  display: none;
}

.modalequipe .modalequipe-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center center;
  max-width: 75vw;
  width: 90%;
  max-height: 90%;
  background: #E4EBED;
  border-radius: 4px;
  box-shadow: 0 0 10px -15px #528FA3;
  border: 1px solid #528FA3;
  color: #000;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  padding: 2rem 7rem;
  overflow-y: scroll;
  display: none;
}

.modalequipe .modalequipe-box .row {
  margin: 3rem 0;
}

.modalequipe .modalequipe-box h2 {
  font-size: 1.85rem;
  font-weight: 600;
  color: #75594C;
}

.modalequipe .modalequipe-box hr {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  background-color: #BFC5C7;
  height: 1px;
  margin-bottom: 4rem;
}

.modalequipe .modalequipe-box .atuacao {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.modalequipe .modalequipe-box .atuacao h2 {
  margin-right: 2rem;
}

.modalequipe .modalequipe-box .social div {
  display: flex;
  align-items: center;
  padding: 0;
}

.modalequipe .modalequipe-box .social h2 {
  margin-right: 1rem;
}

.modalequipe .modalequipe-box .title {
  display: flex;
  align-items: baseline;
}

.modalequipe .modalequipe-box .title h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #272C2E;
  margin: 0 1rem 1rem 0;
}

.modalequipe .modalequipe-box .title h3 {
  font-size: 1.4rem;
  color: #75594C;
  text-transform: uppercase;
}

.modalequipe .modalequipe-box .content {
  color: #084054;
  font-size: 1.45rem;
  font-weight: 500;
}

.modalequipe .modalequipe-header {
  position: relative;
  padding: 15px 0;
  border-bottom: 1px solid #e6e6e6;
}

.modalequipe .modalequipe-header .modalequipe-title {
  padding: 0 40px 0 15px;
  font-size: 22px;
  font-weight: 700;
}

.modalequipe .modalequipe-content {
  padding: 15px;
  font-size: 16px;
  overflow-y: auto;
}

.modalequipe .modalequipe-close {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  background: none;
}

.modalatuacao {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: none;
}

.modalatuacao.open {
  visibility: visible;
  display: block;
}

.modalatuacao.open .modalatuacao-overlay {
  opacity: 1;
  display: block;
}

.modalatuacao.open .modalatuacao-box {
  transform: translate(-50%, -50%) scale(1);
  display: block;
}

.modalatuacao .modalatuacao-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  opacity: 0;
  background: #000;
  background: rgba(0,0,0,0.7);
  display: none;
}

.modalatuacao .modalatuacao-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center center;
  max-width: 75vw;
  width: 90%;
  max-height: 90%;
  background: #E4EBED;
  border-radius: 4px;
  box-shadow: 0 0 10px -15px #528FA3;
  border: 1px solid #528FA3;
  color: #000;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  padding: 5rem 10rem;
  display: none;
  overflow-y: scroll;
}

.modalatuacao .modalatuacao-box .row {
  margin: 4rem 0;
}

.modalatuacao .modalatuacao-box h2 {
  font-size: 1.85rem;
  font-weight: 600;
  color: #75594C;
}

.modalatuacao .modalatuacao-box hr {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  background-color: #BFC5C7;
  height: 1px;
  margin-bottom: 4rem;
}

.modalatuacao .modalatuacao-box .atuacao {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.modalatuacao .modalatuacao-box .atuacao h2 {
  margin-right: 2rem;
}

.modalatuacao .modalatuacao-box .social div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalatuacao .modalatuacao-box .social h2 {
  margin-right: 1rem;
}

.modalatuacao .modalatuacao-box .title {
  display: flex;
  align-items: baseline;
}

.modalatuacao .modalatuacao-box .title h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #272C2E;
  margin: 0 1rem 1rem 0;
}

.modalatuacao .modalatuacao-box .title h3 {
  font-size: 1.4rem;
  color: #75594C;
  text-transform: uppercase;
}

.modalatuacao .modalatuacao-box .content {
  color: #145066;
  padding: 2rem 0;
  font-size: 1.75rem;
  font-weight: 500;
}

.modalatuacao .modalatuacao-box .content p {
  line-height: 1.5;
  margin: 1rem 0;
}

.modalatuacao .modalatuacao-box .content p strong {
  color: #8F7264;
}

.modalatuacao .modalatuacao-box .advogados-row {
  margin-left: 7rem;
}

.modalatuacao .modalatuacao-box .advogados-row h2,
.modalatuacao .modalatuacao-box .advogados-row h3 {
  color: #555E61;
  font-size: 1.75rem;
  font-weight: 600;
}

.modalatuacao .modalatuacao-box .advogados-row-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.modalatuacao .modalatuacao-box .advogados-row-line div {
  margin-left: 1rem;
}

.modalatuacao .modalatuacao-box .advogados-row-line h2 {
  font-size: 1.5rem;
  font-weight: 700;
  max-width: 10rem;
}

.modalatuacao .modalatuacao-box .advogados-row-line h3 {
  font-size: 1.45rem;
  font-weight: 400;
}

.modalatuacao .modalatuacao-box .advogados-row-line figure {
  margin: 2rem 1rem;
}

.modalatuacao .modalatuacao-box .advogados-row-line img {
  width: 10rem;
  border-radius: 60px;
}

.modalatuacao .modalatuacao-header {
  position: relative;
  padding: 15px 0;
  border-bottom: 1px solid #e6e6e6;
}

.modalatuacao .modalatuacao-header .modalatuacao-title {
  padding: 0 40px 0 15px;
  font-size: 22px;
  font-weight: 700;
}

.modalatuacao .modalatuacao-header h2 {
  color: #084054;
  font-size: 2.5rem;
  display: flex;
  align-items: center;
}

.modalatuacao .modalatuacao-header h2 span {
  filter: invert(17%) sepia(62%) saturate(659%) hue-rotate(148deg) brightness(102%) contrast(96%);
}

.modalatuacao .modalatuacao-header h2 span img {
  margin-right: 2rem;
  filter: brightness(0) saturate(100%);
}

.modalatuacao .modalatuacao-content {
  padding: 15px;
  font-size: 16px;
  overflow-y: auto;
}

.modalatuacao .modalatuacao-close {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  background: none;
  font-size: 3rem;
  color: #084054;
}

.pagination {
  display: table;
  border-collapse: collapse;
  margin: 0 auto;
  text-align: center;
}

.pagination li {
  display: table-cell;
  vertical-align: middle;
  list-style: none;
  border: 1px solid #25657C;
}

.pagination li:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.pagination li:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.pagination li.active a {
  background: #25657C;
  color: #fff;
}

.pagination li.disabled a {
  pointer-events: none;
  background: #eee;
}

.pagination a {
  display: block;
  padding: 8px 16px;
  color: #25657C;
}

.pagination a:hover,
.pagination a:focus {
  background: #25657C;
  color: #fff;
}

.title-default {
  font-size: 36px;
  font-weight: 700;
  color: #002488;
  text-transform: uppercase;
}

.title-border {
  position: relative;
  font-size: 38px;
  font-weight: 900;
  text-transform: uppercase;
  color: #A0B2B9;
}

.title-border.border-0:after {
  background: #fff;
}

.title-border.border-1:after {
  background: #25657C;
}

.title-border.border-2:after {
  background: #A0B2B9;
}

.title-border-bottom {
  position: relative;
  padding-bottom: 20px;
  font-size: 38px;
  line-height: 40px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  color: #25657C;
}

.title-border-bottom.border-0:after {
  background: #fff;
}

.title-border-bottom.border-1:after {
  background: #25657C;
}

.title-border-bottom.border-2:after {
  background: #A0B2B9;
}

.title-border-bottom:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 35%;
  height: 2px;
  transform: translateX(-50%);
  background: #A0B2B9;
}

.title-product-home {
  position: relative;
  font-size: 28px;
  font-weight: 900;
  text-transform: uppercase;
  background-color: #f6323e;
  padding: 5px 2rem;
  width: 40rem;
  color: white;
}

.title-product-home::after {
  position: absolute;
  right: -50px;
  margin-top: -5px;
  content: "";
  border-left: 25px solid #A0B2B9;
  border-top: 25px solid #A0B2B9;
  border-right: 25px solid transparent;
  border-bottom: 25px solid transparent;
}

.text-lg {
  font-size: 20px;
}

.text-sm {
  font-size: 18px;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #fff;
}

.text-blue {
  color: #25657C;
}

.text-malina {
  font-family: 'malina1regular';
}

.hero-slider {
  position: relative;
}

.hero-slider-holding {
  position: relative;
}

.hero-slider-stage {
  position: relative;
  height: 100vh;
}

.hero-slider-stage div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease;
  visibility: hidden;
  opacity: 0;
  background-size: cover;
  background-position: center center;
  z-index: 5;
}

.hero-slider-stage div.ativo {
  visibility: visible;
  opacity: 1;
}

.hero-slider-stage .container {
  position: relative;
  height: 100%;
  z-index: 5;
}

.hero-slider-stage .content {
  position: relative;
  top: 50%;
  width: 100%;
  max-width: 650px;
  transform: translateY(-50%);
  color: #fff;
}

.hero-slider-stage p {
  position: relative;
  font-weight: 700;
  font-size: 42px;
  line-height: 45px;
  text-transform: uppercase;
}

.hero-slider-nav .hero-slider-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 6px 18px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 40px;
  line-height: 40px;
  border-radius: 10px;
  transition: all 0.2s ease;
  z-index: 5;
}

.hero-slider-nav .hero-slider-navigation.to-left {
  left: 10%;
}

.hero-slider-nav .hero-slider-navigation.to-right {
  right: 10%;
}

.hero-slider-nav .hero-slider-navigation:hover,
.hero-slider-nav .hero-slider-navigation:focus {
  background: #fff;
  color: #002488;
}

.hero-slider-bullets {
  position: absolute;
  width: 100%;
  bottom: 50px;
  left: 0;
  text-align: center;
  z-index: 5;
}

.hero-slider-bullets>a {
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  margin: 0 4px;
  background: #fff;
}

.hero-slider-bullets>a.ativo {
  background: #A0B2B9;
}

.hero-slider-bullets>a:hover,
.hero-slider-bullets>a:focus {
  background: #A0B2B9;
}

.card-visual {
  position: relative;
  height: 275px;
}

.card-visual>a {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  color: #fff;
  background: #25657C;
  border-radius: 25px;
  overflow: hidden;
  border: 2px solid #25657C;
}

.card-visual>a:hover .card-visual-image,
.card-visual>a:focus .card-visual-image {
  transform: scale(1.2);
}

.card-visual .card-visual-image {
  position: relative;
  width: 100%;
  transition: all 0.5s ease;
}

.card-visual .card-visual-content {
  position: relative;
  padding: 0px 20px 0 20px;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.2;
}

.card-visual .card-visual-content span {
  font-size: 30px;
  line-height: 1.3;
}

.card-post {
  position: relative;
}

.card-post .card-post-image {
  height: 280px;
  margin-bottom: 15px;
}

.card-post .card-post-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.card-post .card-post-content .card-post-meta {
  margin-bottom: 15px;
}

.card-post .card-post-content .card-post-meta .card-post-category {
  font-weight: 700;
  color: #626262;
}

.card-post .card-post-content .card-post-meta .card-post-category+.card-post-date:before {
  content: "| ";
  margin: 0 4px;
}

.card-post .card-post-content .card-post-title {
  margin-bottom: 15px;
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
}

.card-post .card-post-content .card-post-excerpt {
  color: #626262;
  margin-bottom: 15px;
}

.card-post-vertical {
  margin-bottom: 30px;
}

.card-post-vertical .card-post-vertical-image {
  height: 225px;
}

.card-post-vertical .card-post-vertical-image img,
.card-post-vertical .card-post-vertical-image iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.card-post-vertical .figure-video {
  height: 310px;
}

.card-post-vertical .card-post-vertical-content .card-post-vertical-meta {
  margin-bottom: 5px;
}

.card-post-vertical .card-post-vertical-content .card-post-vertical-meta .card-post-vertical-category {
  font-weight: 700;
  color: #626262;
}

.card-post-vertical .card-post-vertical-content .card-post-vertical-meta .card-post-vertical-category+.card-post-vertical-date:before {
  content: "| ";
  margin: 0 4px;
}

.card-post-vertical .card-post-vertical-content .card-post-vertical-date {
  font-weight: 700;
  color: #A0B2B9;
}

.card-post-vertical .card-post-vertical-content .card-post-vertical-title {
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
}

.card-post-vertical .card-post-vertical-content .card-post-vertical-excerpt {
  color: #626262;
  margin-bottom: 10px;
}

.card-row {
  margin-top: 3rem;
}

.card-row-box {
  margin: 0 1rem 2rem;
  padding: 4rem 3rem;
  color: #BAC9CF;
  z-index: 9;
  border: 0.5px solid rgba(110,165,184,0.4);
}

.card-row-box::before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(320deg, rgba(255,255,255,0.0768) 0%, rgba(255,255,255,0.0256) 80%);
  backdrop-filter: blur(20px);
  z-index: 9;
}

.card-row-box .content {
  position: relative;
  z-index: 9;
}

.card-row-box h2 {
  font-size: 2.35rem;
  margin-bottom: 2rem;
  color: #C2A79B;
  font-weight: 600;
  text-transform: uppercase;
}

.card-row-box h3 {
  font-size: 2.15rem;
  color: #528FA3;
  line-height: 0.5;
  padding-top: 1rem;
}

.card-row-box p {
  font-size: 1.5rem;
}

.card-equipe-row {
  margin-top: 3rem;
}

.card-equipe-row-box {
  margin: 0 1rem 10rem;
  padding: 4rem 3rem;
  color: #BAC9CF;
  border: 0.5px solid rgba(110,165,184,0.4);
}

.card-equipe-row-box::before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(320deg, rgba(255,255,255,0.0768) 0%, rgba(255,255,255,0.0256) 80%);
}

.card-equipe-row-box .content {
  position: relative;
  z-index: 9;
}

.card-equipe-row-box h2 {
  font-size: 1.75rem;
  color: #FAFAFA;
  font-weight: 600;
  text-transform: uppercase;
}

.card-equipe-row-box h3 {
  font-size: 1.35rem;
  margin-bottom: 2rem;
  color: #DBB6A4;
  text-transform: uppercase;
}

.card-equipe-row-box p {
  font-size: 1.45rem;
  color: #D5E7ED;
}

.card-equipe-row-box figure {
  margin-top: -10rem;
  margin-bottom: 2rem;
}

.card-equipe-row-box .president-border {
  border: 10px solid white;
}

.card-equipe-row-box.socio-card {
  background-color: #E4EBED;
}

.card-equipe-row-box.socio-card h2 {
  color: #272C2E;
}

.card-equipe-row-box.socio-card h3 {
  color: #75594C;
}

.card-equipe-row-box.socio-card p {
  color: #084054;
}

.card-atuacao-row {
  margin-top: 3rem;
}

.card-atuacao-row-box {
  margin: 0 1rem 10rem;
  padding: 4rem 3rem;
  color: #BAC9CF;
  border: 0.5px solid rgba(110,165,184,0.4);
  z-index: 9;
  position: relative;
}

.card-atuacao-row-box::before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(320deg, rgba(255,255,255,0.0768) 0%, rgba(255,255,255,0.0256) 80%);
  z-index: 1;
}

.card-atuacao-row-box .content {
  position: relative;
  z-index: 9;
}

.card-atuacao-row-box h2 {
  font-size: 1.75rem;
  color: #FAFAFA;
  font-weight: 600;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.card-atuacao-row-box h2 img {
  margin-right: 2rem;
}

.card-atuacao-row-box a.button {
  font-size: 1.35rem;
  margin-bottom: 2rem;
  color: #DBB6A4;
}

.card-atuacao-row-box a.button:hover,
.card-atuacao-row-box a.button:focus {
  text-decoration: underline;
}

.card-atuacao-row-box p {
  font-size: 1.45rem;
  margin-bottom: 2rem;
  color: #D5E7ED;
}

.card-atuacao-row-box figure {
  margin-top: -10rem;
  margin-bottom: 2rem;
  z-index: 9;
  position: relative;
}

.card-atuacao-row-box figure::after {
  content: "";
  background: url("../img/play_circle.png");
  display: block;
  position: absolute;
  width: 82px;
  height: 82px;
  left: 50%;
  margin-left: -41px;
  top: 50%;
  margin-top: -41px;
}

.title-background {
  position: absolute;
  z-index: 1;
  padding: 3rem;
  top: 0;
  left: 0;
}

.title-background h2 {
  font-size: 3.75rem;
  color: #C2A79B;
  font-weight: 600;
  text-transform: uppercase;
}

.title-background h3 {
  font-size: 2.35rem;
  color: #528FA3;
}

.btn {
  color: #25657C;
  text-align: center;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  font-weight: 400;
  border-radius: 4px;
  background: #FAFAFA;
  font-size: 1.85rem;
}

.btn:hover,
.btn:focus {
  background: #25657C;
  color: #FAFAFA;
}

.btn.btn-grey-modal {
  background: #C7D4D9;
  color: #3D4547;
  border-radius: 32px;
  font-weight: 700;
}

.btn.btn-grey-modal:hover,
.btn.btn-grey-modal:focus {
  background: #C7D4D9;
  color: #3D4547;
}

.btn.btn-blue {
  background: #25657C;
  color: #fff;
}

.btn.btn-blue:hover,
.btn.btn-blue:focus {
  color: #25657C;
  background: #fff;
}

.btn.btn-light-blue {
  background: #D5E7ED;
  color: #fff;
}

.btn.btn-light-blue:hover,
.btn.btn-light-blue:focus {
  color: #D5E7ED;
  background: #fff;
}

.btn.btn-red {
  background: #A0B2B9;
  border: 2px solid #A0B2B9;
  color: #fff;
  border-radius: 5px;
  width: 32rem !important;
}

.btn.btn-red:hover,
.btn.btn-red:focus {
  background: #fff;
  color: #A0B2B9;
  border: 2px solid #A0B2B9;
}

.btn.btn-azul {
  background: #25657C;
  color: #fff;
  font-style: italic;
  border-radius: 5px;
}

.btn.btn-azul:hover,
.btn.btn-azul:focus {
  background: #fff;
  color: #25657C;
  border: 2px solid #25657C;
}

.btn.btn-danger {
  background: #e00519;
}

.btn.btn-danger:hover,
.btn.btn-danger:focus {
  background: #ae0413;
}

.btn.btn-grey {
  background: #929497;
}

.btn.btn-grey:hover,
.btn.btn-grey:focus {
  background: #787a7e;
}

.btn.btn-facebook {
  background: #3b5998;
}

.btn.btn-facebook:hover,
.btn.btn-facebook:focus {
  background: #2d4373;
}

.btn.btn-twitter {
  background: #55acee;
}

.btn.btn-twitter:hover,
.btn.btn-twitter:focus {
  background: #2795e9;
}

.btn.btn-pinterest {
  background: #ca2728;
}

.btn.btn-pinterest:hover,
.btn.btn-pinterest:focus {
  background: #9f1f20;
}

.btn.btn-google-plus {
  background: #dd4b39;
}

.btn.btn-google-plus:hover,
.btn.btn-google-plus:focus {
  background: #c23321;
}

.btn.btn-email {
  background: #307ab7;
}

.btn.btn-email:hover,
.btn.btn-email:focus {
  background: #255f8f;
}

.btn.btn-lg {
  padding: 9px 85px;
  width: 21rem;
}

.btn.btn-md {
  padding: 9px 40px;
  margin: 0 1rem;
  width: 21rem;
}

.btn.btn-block {
  width: 100%;
  display: block;
}

.btn i {
  margin-left: 3rem;
}

.page-header {
  position: relative;
  padding: 10rem 0 5rem;
}

.page-header .title-highlight {
  display: flex;
  align-items: end;
}

.page-header .title-highlight-div {
  margin: 0 10px;
}

.page-header .title-highlight-retangulo {
  margin-bottom: 1.5rem;
  margin-left: 2rem;
  width: 40%;
  height: 0.65rem;
  background-color: #25657C;
}

.page-header .title-highlight .bg-light {
  background-color: #A0B2B9;
}

.page-header .container {
  display: flex;
  align-items: center;
}

.page-header .hr-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-header strong {
  color: #8DBAC9;
}

.page-header h2 {
  font-size: 4.75rem;
  color: #C2A79B;
  font-weight: 600;
  text-transform: uppercase;
}

.page-header h3 {
  font-size: 3.25rem;
  color: #39788F;
  line-height: 0.5;
  padding-top: 1rem;
}

.page-return {
  padding: 100px 0;
  text-align: center;
}

.page-return .page-return-title {
  font-size: 80px;
  font-weight: 700;
  color: #25657C;
  text-transform: uppercase;
}

.page-return .page-return-subtitle {
  margin-bottom: 20px;
  font-size: 24px;
}

.page-return .page-return-description {
  margin-bottom: 20px;
}

.carousel-partners .owl-item {
  position: relative;
  height: 80px;
  text-align: center;
}

.carousel-partners .owl-item>div {
  height: 100%;
}

.carousel-partners .owl-item>div img {
  position: relative;
  top: 50%;
  width: auto;
  max-width: 90%;
  max-height: 90%;
  margin: 0 auto;
  transform: translateY(-50%);
}

.carousel-partners .owl-controls {
  margin-top: 50px;
}

.carousel-partners .owl-controls .owl-dots {
  text-align: center;
}

.carousel-partners .owl-controls .owl-dots>div {
  width: 14px;
  height: 14px;
  display: inline-block;
  background: #fff;
  border-radius: 100%;
  margin: 0 4px;
}

.carousel-partners .owl-controls .owl-dots>div.active {
  background: #ccc;
}

.carousel-partners .owl-controls .owl-dots>div:hover,
.carousel-partners .owl-controls .owl-dots>div:focus {
  background: #ccc;
}

.carousel-product .owl-item>div {
  position: relative;
  height: 80px;
}

.carousel-product .owl-item>div>a {
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #25657C;
  transition: all 0.2s ease;
}

.carousel-product .owl-item>div>a:hover,
.carousel-product .owl-item>div>a:focus {
  opacity: 0.7;
}

.carousel-product .owl-item>div>a.active {
  box-shadow: 0 0 4px 0 #25657C inset;
}

.carousel-product .owl-item>div img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-width: 90%;
  max-height: 90%;
  width: auto;
  display: block;
  margin: 0 auto;
}

.carousel-product .owl-controls {
  margin-top: 20px;
}

.carousel-product .owl-controls .owl-dots {
  text-align: center;
}

.carousel-product .owl-controls .owl-dots>div {
  width: 14px;
  height: 14px;
  display: inline-block;
  background: #ccc;
  border-radius: 100%;
  margin: 0 4px;
}

.carousel-product .owl-controls .owl-dots>div.active {
  background: #A0B2B9;
}

.carousel-product .owl-controls .owl-dots>div:hover,
.carousel-product .owl-controls .owl-dots>div:focus {
  background: #25657C;
}

.carousel-product-home .owl-item>div {
  position: relative;
  height: 500px;
}

.carousel-product-home .owl-item>div>a {
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #25657C;
  transition: all 0.2s ease;
}

.carousel-product-home .owl-item>div>a:hover,
.carousel-product-home .owl-item>div>a:focus {
  opacity: 0.7;
}

.carousel-product-home .owl-item>div>a.active {
  box-shadow: 0 0 4px 0 #25657C inset;
}

.carousel-product-home .owl-item>div img {
  position: relative;
  top: 30%;
  transform: translateY(-50%);
  max-width: 95%;
  max-height: 95%;
  width: auto;
  display: block;
  margin: 0 auto;
  margin-top: 1.5rem;
}

.carousel-product-home .owl-controls {
  margin-top: 20px;
}

.carousel-product-home .owl-controls .owl-dots {
  text-align: center;
}

.carousel-product-home .owl-controls .owl-dots>div {
  width: 14px;
  height: 14px;
  display: inline-block;
  background: #ccc;
  border-radius: 100%;
  margin: 0 4px;
}

.carousel-product-home .owl-controls .owl-dots>div.active {
  background: #25657C;
}

.carousel-product-home .owl-controls .owl-dots>div:hover,
.carousel-product-home .owl-controls .owl-dots>div:focus {
  background: #25657C;
}

.carousel-product-home .owl-controls img {
  width: 90%;
}

.carousel-product-home .owl-prev {
  position: absolute;
  float: left;
  left: -5%;
  top: 30%;
}

.carousel-product-home .owl-next {
  position: absolute;
  float: left;
  right: -5%;
  top: 30%;
}

.navigation-inner {
  margin-bottom: 20px;
}

.navigation-inner .navigation-inner-trigger {
  display: block;
  margin-bottom: 15px;
  color: #25657C;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 28px;
  transition: all 0.2s ease;
}

.navigation-inner .navigation-inner-trigger:hover,
.navigation-inner .navigation-inner-trigger:focus {
  color: #1f5568;
}

.navigation-inner .navigation-inner-trigger.active {
  color: #A0B2B9;
  pointer-events: none;
}

.navigation-inner .navigation-inner-trigger-2 {
  display: block;
  margin-bottom: 15px;
  color: #25657C;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 28px;
  transition: all 0.2s ease;
}

.navigation-inner .navigation-inner-trigger-2:hover,
.navigation-inner .navigation-inner-trigger-2:focus {
  color: #1f5568;
}

.navigation-inner .navigation-inner-trigger-2.active {
  color: #A0B2B9;
  pointer-events: none;
}

.navigation-inner .navigation-inner-list {
  display: none;
}

.navigation-inner .navigation-inner-list li {
  position: relative;
  margin-bottom: 5px;
  list-style: none;
}

.navigation-inner .navigation-inner-list li:before {
  content: "\f105";
  font-family: 'FontAwesome';
  color: #A0B2B9;
  margin-right: 8px;
}

.navigation-inner .navigation-inner-list a {
  color: #A0B2B9;
}

.navigation-inner .navigation-inner-list a:hover,
.navigation-inner .navigation-inner-list a:focus {
  color: #A0B2B9;
}

.google-maps {
  position: relative;
  width: 100%;
  height: 400px;
  vertical-align: middle;
}

.list-social {
  display: inline-block;
}

.list-social li {
  display: inline-block;
  list-style: none;
  margin-right: 1rem;
}

.list-social li a {
  width: 30px;
  height: 30px;
  display: block;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  border-radius: 100%;
  background: #fff;
  color: #25657C;
  transition: all 0.2s ease;
}

.list-social li a:hover,
.list-social li a:focus {
  transform: scale(1.1);
}

.list-social li i {
  vertical-align: middle;
}

.list-team li {
  list-style: none;
}

.list-team .team-item .team-item-avatar {
  width: 175px;
  height: 175px;
  margin: 0 auto 10px;
  background: #fff;
  border: 12px solid #25657C;
  border-radius: 100%;
  overflow: hidden;
}

.list-team .team-item .team-item-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.list-team .team-item .team-item-name {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
}

.list-sitemap li {
  list-style: none;
}

.list-sitemap li:first-child a {
  padding-top: 0;
}

.list-sitemap li+li {
  border-top: 1px solid #A0B2B9;
}

.list-sitemap li a:hover,
.list-sitemap li a:focus {
  color: #25657C;
}

.list-sitemap a {
  display: block;
  padding: 15px 0;
  color: #A0B2B9;
  text-transform: uppercase;
  font-size: 1.3rem;
}

.list-sitemap a:hover,
.list-sitemap a:focus {
  color: #25657C;
}

.list-credits li {
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle;
  list-style: none;
}

.list-share li {
  margin: 0 5px 10px 0;
  display: inline-block;
  list-style: none;
}

.list-share a {
  padding: 10px;
  text-align: center;
  min-width: 50px;
}

.list-share a span {
  margin-left: 6px;
}

.logo {
  float: left;
  padding: 25px 0;
}

.logo a {
  display: block;
}

.header {
  position: fixed;
  z-index: 99;
  backdrop-filter: blur(10px);
  background: #002f40;
  background: radial-gradient(circle, rgba(0,47,64,0.4) 15%, rgba(0,47,64,0.05) 100%);
}

.header-information {
  padding: 28.5px 0;
}

.header-information .header-information-text {
  float: left;
  text-align: right;
  position: absolute;
  right: 0;
  background-color: #A0B2B9;
  height: 4.6rem;
  color: white;
  width: 22rem;
  border-radius: 30px 0px 0px 30px;
  padding: 8px 30px;
}

.header-information .header-information-text strong {
  font-size: 2rem;
}

.header-information .header-information-text a {
  color: white;
}

.navigation {
  position: relative;
  float: right;
}

.navigation .menu {
  text-align: center;
}

.navigation .menu .menu-item {
  position: relative;
  display: inline-block;
  list-style: none;
}

.navigation .menu .menu-item.active>a {
  color: #fff;
  font-weight: 600;
  display: inline-block;
  padding: 5px 15px;
  border-bottom: 2px solid #fff;
}

.navigation .menu .menu-item>a {
  display: block;
  padding: 40px 15px;
  color: #BAC9CF;
}

.navigation .menu .menu-item>a:hover,
.navigation .menu .menu-item>a:focus {
  color: #fff;
  display: inline-block;
  padding: 5px 15px;
  border-bottom: 2px solid #fff;
}

.navigation .menu .menu-item .menu-item-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 150%;
  padding: 15px;
  background: #fff;
  box-shadow: 0px 0px 7px -3px #000;
  border-radius: 6px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease;
  transform: translateY(-15px);
  z-index: 15;
}

.navigation .menu .menu-item .menu-item-dropdown:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  height: 0;
  width: 0;
  margin-left: -12px;
  pointer-events: none;
  border: solid transparent;
  border-color: rgba(255,255,255,0);
  border-bottom-color: #fff;
  border-width: 12px;
}

.navigation .menu .menu-item .menu-item-dropdown .dropdown-item {
  list-style: none;
  position: relative;
}

.navigation .menu .menu-item .menu-item-dropdown .dropdown-item+.dropdown-item {
  margin-top: 10px;
}

.navigation .menu .menu-item .menu-item-dropdown .dropdown-item>a {
  text-transform: uppercase;
  color: #25657C;
  font-weight: 700;
}

.navigation .menu .menu-item .menu-item-dropdown .dropdown-item>a:hover,
.navigation .menu .menu-item .menu-item-dropdown .dropdown-item>a:focus {
  color: #25657C;
}

.navigation .menu .menu-item .menu-item-dropdown .dropdown-item>ul {
  display: none;
  position: absolute;
}

.navigation .menu .menu-item .menu-item-dropdown .dropdown-item:hover>ul {
  display: block;
  left: 100%;
  min-width: 220px;
  margin-top: -43px;
  position: absolute;
  background: #fff;
  border-radius: 6px;
  z-index: 12;
}

.navigation .menu .menu-item .menu-item-dropdown .dropdown-item:hover>ul>li {
  list-style: none;
  font-size: 15px;
  float: none;
  padding: 5px;
  display: block;
}

.navigation .menu .menu-item .menu-item-dropdown .dropdown-item:hover>ul>li:hover {
  font-size: 17px;
  font-weight: 700;
}

.navigation .menu .menu-item .menu-item-dropdown .dropdown-item:hover>ul>li>a {
  color: #25657C;
}

.navigation .menu.active {
  display: grid;
}

.navigation-mobile {
  position: relative;
  float: initial;
}

.btn-hamburguer {
  display: block;
  padding: 30px 0 0;
  color: #fff;
  font-size: 26px;
  text-align: center;
  background: none;
  cursor: pointer;
}

.change-language {
  padding: 9px;
}

.change-language img {
  vertical-align: baseline;
}

.footer {
  background: linear-gradient(0deg, #000 0%, #002F40 100%);
}

.footer .footer-information {
  padding: 100px 0 30px;
}

.footer .footer-information .footer-information-featured {
  padding: 0px;
  border-radius: 6px;
  color: #A0B2B9;
}

.footer .footer-information .footer-information-featured p {
  margin-bottom: 25px;
  color: #A0B2B9;
  font-size: 1.3rem;
}

.footer .footer-information .footer-information-featured p+p {
  padding-top: 10px;
}

.footer .footer-information .footer-information-featured a {
  color: #A0B2B9;
}

.footer .footer-information .footer-information-featured a:hover,
.footer .footer-information .footer-information-featured a:focus {
  color: #25657C;
}

.footer .footer-information .footer-information-featured .big {
  font-size: 3rem;
  color: #fff !important;
}

.footer .footer-information .footer-heading {
  font-weight: 600;
  font-size: 2rem;
  color: #39788F;
}

.footer .footer-information .footer-heading.map:before {
  content: url(../img/icons/map.png);
  margin-left: -25px;
  padding-right: 10px;
}

.footer .footer-information .footer-heading.onde:before {
  content: url(../img/icons/hamburger.png);
  margin-left: -25px;
  padding-right: 10px;
}

.footer .footer-information .footer-anchor {
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
}

.footer .footer-information .footer-anchor:hover,
.footer .footer-information .footer-anchor:focus {
  color: #A0B2B9;
}

.footer .footer-copyright {
  padding: 15px 0;
  font-size: 14px;
}

.footer .footer-copyright .footer-copyright-disclaimer {
  padding: 3px 0;
  color: #fff;
  font-size: 14px;
}

.footer hr {
  height: 1px;
  background-color: #A0B2B9;
  margin-bottom: 1rem;
}

.section-main {
  background: url("../img/dots.png"),radial-gradient(circle, rgba(37,101,124,0.65) 13%, rgba(37,101,124,0) 95%),#000;
  background-attachment: fixed;
  background-position: center;
}

.section-main-inner {
  background: url("../img/dots.png"),radial-gradient(circle, rgba(37,101,124,0.65) 13%, rgba(37,101,124,0) 95%),#000;
  background-attachment: fixed;
  background-position: center;
}

.section-default {
  padding: 60px 0 30px;
}

.section-default.featured {
  background: rgba(0,38,52,0.4);
  backdrop-filter: blur(20px);
  margin-top: -15rem;
  z-index: 9;
  position: relative;
  text-align: center;
}

.section-default.featured p {
  font-weight: 300;
  font-size: 6rem;
  color: #A0B2B9;
}

.section-default.dark-blue {
  color: #FAFAFA;
}

.section-historia {
  padding: 10rem 0 8rem;
  background-color: #E4EBED;
}

.section-historia .title-highlight {
  display: flex;
  align-items: end;
  padding: 3rem 0px 0;
  margin-left: -1rem;
}

.section-historia .title-highlight-div {
  margin: 0 10px;
}

.section-historia .title-highlight-retangulo {
  margin-bottom: 1.5rem;
  margin-left: 2rem;
  width: 100%;
  height: 0.65rem;
  background-color: #A0B2B9;
}

.section-historia .title-highlight::before {
  content: "";
  background: url("../img/logo-icon-grey.png");
  position: absolute;
  top: 1rem;
  width: 7rem;
  height: 95px;
  margin-left: -7rem;
  background-repeat: no-repeat;
}

.section-historia .content {
  padding: 2rem 0;
  color: #25657C;
  font-size: 1.75rem;
  font-weight: 500;
}

.section-historia .hr-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-historia strong {
  color: #8DBAC9;
}

.section-historia h2 {
  font-size: 4.25rem;
  color: #8F7264;
  font-weight: 600;
  text-transform: uppercase;
}

.section-historia h3 {
  font-size: 3.25rem;
  color: #39788F;
  line-height: 0.5;
  padding-top: 1rem;
}

.section-historia p+p {
  margin-top: 1rem;
}

.section-missao .title-highlight {
  display: flex;
  align-items: end;
  padding: 1rem 0;
  margin-left: -1rem;
}

.section-missao .title-highlight-div {
  margin: 0 10px;
}

.section-missao .title-highlight-retangulo {
  margin-bottom: 1.5rem;
  margin-left: 2rem;
  width: 60%;
  height: 0.65rem;
  background-color: #A0B2B9;
}

.section-missao .content {
  padding: 1rem 3rem 11rem 0;
  color: #C7D4D9;
  font-size: 1.95rem;
}

.section-missao .hr-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-missao strong {
  color: #8DBAC9;
}

.section-missao h2 {
  font-size: 3.75rem;
  color: #C2A79B;
  font-weight: 600;
  text-transform: uppercase;
}

.section-missao h3 {
  font-size: 3.25rem;
  color: #39788F;
  line-height: 0.5;
  padding-top: 1rem;
}

.section-missao p+p {
  margin-top: 1rem;
}

.section-valores {
  padding: 10rem 0 8rem;
  background: linear-gradient(160deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 34%, rgba(255,255,255,0) 100%),#002F40;
}

.section-valores .title-highlight {
  display: flex;
  align-items: end;
  padding: 1rem 2rem;
}

.section-valores .title-highlight-retangulo {
  margin-bottom: 5px;
}

.section-valores .title-highlight::before {
  content: "";
  background: url("../img/logo-icon.png");
  position: absolute;
  top: 1rem;
  width: 7rem;
  height: 95px;
  margin-left: -7rem;
  background-repeat: no-repeat;
}

.section-valores .hr-section {
  display: flex;
  align-items: end;
  justify-content: center;
}

.section-valores .hr-section::before {
  content: "";
  background: url("../img/retangulo-dark.png");
  width: 25rem;
  height: 0.65rem;
  margin-right: 3rem;
}

.section-valores .hr-section::after {
  content: "";
  background: url("../img/retangulo-dark.png");
  width: 25rem;
  height: 0.65rem;
  margin-left: 3rem;
}

.section-valores .hr-section h2 {
  font-size: 3.75rem;
  color: #C2A79B;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 0.8;
}

.section-valores .hr-section h3 {
  font-size: 3.25rem;
  color: #39788F;
}

.section-valores strong {
  color: #8DBAC9;
}

.section-valores p+p {
  margin-top: 1rem;
}

.section-estrutura {
  padding: 60px 0 30px;
}

.section-estrutura-equipe {
  padding: 2rem 2rem 8rem;
}

.section-estrutura-equipe .btn {
  margin-top: -10rem;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 40rem;
  z-index: 9;
}

.section-estrutura-equipe figure::before {
  content: "";
  background-color: #145066;
  position: absolute;
  top: -8rem;
  width: 30rem;
  height: 50rem;
  margin-left: -7rem;
  background-repeat: no-repeat;
  z-index: 1;
}

.section-estrutura-equipe figure::after {
  content: "";
  background-color: #A0B2B9;
  position: absolute;
  bottom: -4rem;
  width: 50vw;
  height: 1rem;
  background-repeat: no-repeat;
  z-index: 1;
  right: -20vw;
}

.section-estrutura-equipe figure img {
  z-index: 9;
  position: relative;
}

.section-estrutura-nossa .title-highlight {
  display: flex;
  align-items: end;
  padding: 3rem 0px 0;
  margin-left: -1rem;
}

.section-estrutura-nossa .title-highlight-div {
  margin: 0 10px;
}

.section-estrutura-nossa .title-highlight-retangulo {
  margin-bottom: 1.5rem;
  margin-left: 2rem;
  width: 60%;
  height: 0.65rem;
  background-color: #25657C;
}

.section-estrutura-nossa .title-highlight::before {
  content: "";
  background: url("../img/logo-icon.png");
  position: absolute;
  background-repeat: no-repeat;
  top: 1rem;
  width: 7rem;
  height: 95px;
  margin-left: -7rem;
}

.section-estrutura-nossa .content {
  padding: 3rem 0px 0;
  margin-left: -1rem;
  font-size: 1.75rem;
}

.section-estrutura-nossa .hr-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-estrutura-nossa strong {
  color: #8DBAC9;
}

.section-estrutura-nossa h2 {
  font-size: 3.75rem;
  color: #C2A79B;
  font-weight: 600;
  text-transform: uppercase;
}

.section-estrutura-nossa h3 {
  font-size: 3.25rem;
  color: #39788F;
  line-height: 0.5;
  padding-top: 1rem;
}

.section-estrutura-advogado div {
  padding: 2rem 2rem;
}

.section-estrutura-advogado .hr-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-estrutura-advogado .hr-section :before {
  content: "";
  background: url("../img/retangulo.png");
  width: 15rem;
  height: 4px;
  margin-right: 3rem;
  display: inline-block;
  vertical-align: super;
}

.section-estrutura-advogado .hr-section :after {
  content: "";
  background: url("../img/retangulo.png");
  width: 15rem;
  height: 4px;
  margin-left: 3rem;
  display: inline-block;
  vertical-align: super;
}

.section-estrutura-advogado h2 {
  font-size: 2.5rem;
  margin: 0 2rem;
  color: #FAFAFA;
}

.section-estrutura p+p {
  margin-top: 1rem;
}

.section-estrutura .container-mosaico {
  display: grid;
  height: 400px;
  gap: 15px;
  grid-template-columns: 175px 175px 175px;
  grid-template-rows: 95px 95px 95px 95px;
  grid-template-areas: "item-1 item-1 item-2" "item-1 item-1 item-2" "item-3 item-4 item-2" "item-5 item-6 item-7";
}

.section-estrutura .container-mosaico a {
  background-size: cover;
}

.section-estrutura .container-mosaico .item-1 {
  grid-area: item-1;
  background-image: url("../img/mosaico/1.png");
}

.section-estrutura .container-mosaico .item-2 {
  grid-area: item-2;
  background-image: url("../img/mosaico/2.png");
}

.section-estrutura .container-mosaico .item-3 {
  grid-area: item-3;
  background-image: url("../img/mosaico/3.png");
}

.section-estrutura .container-mosaico .item-4 {
  grid-area: item-4;
  background-image: url("../img/mosaico/4.png");
}

.section-estrutura .container-mosaico .item-5 {
  grid-area: item-5;
  background-image: url("../img/mosaico/5.png");
}

.section-estrutura .container-mosaico .item-6 {
  grid-area: item-6;
  background-image: url("../img/mosaico/6.png");
}

.section-estrutura .container-mosaico .item-7 {
  grid-area: item-7;
  background-image: url("../img/mosaico/7.png");
}

.section-partners {
  padding: 60px 0;
  background: #25657C;
}

.section-thin {
  padding: 30px 0 50px;
}

::selection {
  background-color: #d1d3d4;
  color: #2d2a2b;
}

@font-face {
  font-family: 'malina1regular';
  src: url("../fonts/malina1-webfont.woff2") format("woff2"),url("../fonts/malina1-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.newsletter {
  padding: 30px 0;
  background: #25657C;
  color: #fff;
}

.newsletter .newsletter-text {
  text-transform: uppercase;
  text-align: right;
  font-size: 18px;
  line-height: 24px;
}

.about p {
  line-height: 2;
  color: #000;
}

.about .about-title {
  font-style: italic;
}

.about .about-title span {
  display: block;
  font-size: 4rem;
  line-height: 1;
}

.about .about-title span .big {
  font-size: 42px;
}

.about .about-title span .big img {
  width: 23rem;
  margin-left: 1rem;
}

.about .about-title strong {
  font-size: 5rem;
}

.about .about-image {
  text-align: center;
}

.about .about-image img {
  max-width: 85%;
  float: left;
}

.about-products img {
  width: 25rem;
  height: 20rem;
}

.about-products p {
  text-align: center;
  font-weight: 700;
  font-size: 3rem;
}

.about-gallery {
  width: 100%;
  height: 280px;
}

.about-gallery a {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 6px;
  overflow: hidden;
}

.about-gallery a:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: #25657C;
  background: rgba(37,101,124,0.7);
  transition: all 0.2s ease;
}

.about-gallery a:hover img,
.about-gallery a:focus img {
  transform: scale(1.2);
}

.about-gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}

.product-ajax {
  min-height: 300px;
}

.product-ajax .product-loading {
  padding-top: 40px;
  font-size: 0;
  text-align: center;
}

.product-ajax .product-loading:before {
  content: url(../img/loading.gif);
}

.product-item {
  position: relative;
}

.product-item>a {
  width: 100%;
  height: 100%;
  display: block;
  color: #002488;
}

.product-item>a:hover,
.product-item>a:focus {
  color: #25657C;
}

.product-item .product-item-image {
  width: 100%;
  height: 450px;
  text-align: center;
  overflow: hidden;
  border: 2px solid;
  border-radius: 5px;
}

.product-item .product-item-image img {
  position: relative;
  top: 30%;
  transform: translateY(-50%);
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
  margin-top: 2rem;
}

.product-item .product-item-content {
  padding: 5px 3rem;
  text-align: left;
  margin-top: -2rem;
}

.product-item .product-item-content .product-item-title {
  font-size: 1.4rem;
  text-transform: uppercase;
}

.product-item .product-item-content .product-item-subtitle {
  font-weight: 700;
  font-size: 2rem;
  color: #A0B2B9;
  text-transform: uppercase;
  margin-bottom: 2rem;
  height: 5rem;
}

.product-item .product-item-content-inner {
  margin-top: -3rem;
}

.product .product-view-image {
  position: relative;
  width: 100%;
  height: 450px;
  margin-bottom: 20px;
  border: 1px solid #25657C;
  border-radius: 10px;
  overflow: hidden;
}

.product .product-view-image img {
  position: relative;
  top: 50%;
  display: block;
  margin: 0 auto;
  transform: translateY(-50%);
  max-width: 100%;
  max-height: 100%;
  transition: 0.7s all ease-in-out;
}

.product .product-view-subtitle {
  color: #001aa9;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
}

.product .product-view-title {
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #A0B2B9;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 4rem;
  border-bottom: 3px solid #A0B2B9;
}

.product .product-view-title-bottom {
  padding-bottom: 15px;
  margin-bottom: 15px;
  color: #A0B2B9;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 26px;
  border-bottom: 4px solid #929497;
}

.product .product-view-description {
  margin-bottom: 15px;
  font-size: 18px;
}

.product .product-view-description p {
  margin-bottom: 15px;
  color: #000;
}

.product .product-view-meta {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #25657C;
  color: #626262;
}

.product .product-view-meta span {
  margin-right: 10px;
}

.product .product-view-meta span+span:before {
  content: "| ";
  margin-right: 10px;
}

.post-view {
  position: relative;
  margin-bottom: 50px;
}

.post-view .post-view-head {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #25657C;
}

.post-view .post-view-head .post-view-head-title {
  margin-bottom: 20px;
  font-size: 32px;
  line-height: 36px;
  text-transform: uppercase;
  font-weight: 700;
}

.post-view .post-view-head .post-view-head-meta span {
  vertical-align: middle;
  margin-right: 20px;
}

.post-view .post-view-head .post-view-head-date {
  color: #626262;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  margin-right: 20px;
}

.post-view .post-view-head .post-view-head-views {
  color: #626262;
  text-transform: lowercase;
  font-size: 14px;
}

.post-view .post-view-body {
  margin-bottom: 40px;
}

.post-view .post-view-body .post-view-body-image {
  margin-bottom: 40px;
}

.post-view .post-view-body .post-view-body-image img {
  border-radius: 10px;
}

.post-view .post-view-body .post-view-body-content p {
  margin-bottom: 20px;
}

.post-view .post-view-body .post-view-body-content a {
  color: #25657C;
}

.post-view .post-view-body .post-view-body-content ul li {
  list-style-position: inside;
}

.post-view .post-view-footer {
  padding-top: 40px;
  border-top: 1px solid #25657C;
}

.contact-information {
  padding: 30px 0 10px;
  background: #25657C;
  color: #fff;
}

.contact-information .contact-information-item {
  position: relative;
  height: 65px;
  padding-left: 65px;
}

.contact-information .contact-information-item .contact-information-item-icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 50px;
  vertical-align: middle;
}

.contact-information .contact-information-item .contact-information-item-text {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}

.contact .content {
  margin-bottom: 7rem;
}

.contact .content h2 {
  color: #39788F;
  font-size: 2.5rem;
  margin-left: 1rem;
}

.contact .content h2 i {
  width: 4rem;
}

.contact .content h3 {
  margin-left: 6rem;
  margin-bottom: 5rem;
}

.contact .content h3 a {
  font-size: 1.75rem;
  color: #C7D4D9;
}

.contact .content h3 a:hover,
.contact .content h3 a:focus {
  color: #25657C;
}

.contact .content figure {
  z-index: 9;
  position: relative;
}

.contact .content::before {
  content: "";
  background-color: #002F40;
  position: absolute;
  top: 0;
  width: 25vw;
  height: 50rem;
  background-repeat: no-repeat;
  z-index: 1;
  right: 15vw;
}

.linha-carrinho {
  display: table;
  height: 200px;
}

.linha-carrinho-inside {
  padding: 0 10px;
  display: table-cell;
  vertical-align: middle;
}

.row-carrinho {
  border: 1px solid;
}

.image-carrinho img {
  border-right: 1px solid #312f2d;
  border-image: linear-gradient(to bottom, rgba(49,47,45,0) 25%, #312f2d 25%, #312f2d 75%, rgba(49,47,45,0) 75%);
  border-image-slice: 1;
}

.button-carrinho {
  float: right;
}

.div-btn-cart {
  position: relative;
  width: 50px;
  float: left;
}

.btn-cart {
  height: 45px;
  width: 45px;
  border: 1px solid #25657C;
  color: #25657C;
  background: none;
  font-size: 26px;
  border-radius: 10px;
}

.btn-cart:hover {
  color: #fff;
  background: #25657C;
}

.btn-cart:hover ~ .btn-cart[data-badge]:after {
  color: #25657C !important;
  background: none !important;
}

.btn-cart[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: .7em;
  background: #25657C;
  color: white;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  box-shadow: 0 1px 8px #333;
}

.breadcrumb {
  padding: 10px 0 10px 0;
}

.breadcrumb li {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  *display: inline;
  color: #929497;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 700;
}

.breadcrumb li+li:before {
  content: "/";
  margin: 0 4px;
}

.breadcrumb a {
  color: inherit;
}

.breadcrumb a:hover,
.breadcrumb a:focus {
  color: #A0B2B9;
}

.md-25 {
  width: 20% !important;
}

.row-selos figure {
  height: 18rem;
}

.row-selos div {
  width: 30%;
  float: left;
  text-align: center;
  margin: 0 1.5%;
}

.row-selos p {
  color: #000;
}

.row-icons {
  color: #6c6c6c;
}

.row-icons a {
  color: #6c6c6c;
}

.row-icons a:hover,
.row-icons a:focus {
  text-decoration: underline;
}

.row-icons figure {
  height: 8rem;
}

.row-icons div {
  width: 20%;
  float: left;
  text-align: center;
  margin: 0 2%;
}

.half-left {
  width: 49.5%;
  float: left;
  margin-right: 1%;
}

.half-right {
  width: 49.5%;
  float: right;
}

.list-clientes li {
  list-style: none;
  float: left;
  width: 14%;
}

.equipe {
  background: #E4EBED;
  padding: 15rem 0 5rem;
}

.equipe .equipe-box {
  border-radius: 4px;
  color: #000;
}

.equipe .equipe-box .row {
  margin: 4rem 0;
}

.equipe .equipe-box h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #75594C;
}

.equipe .equipe-box hr {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  background-color: #BFC5C7;
  height: 1px;
  margin-bottom: 4rem;
}

.equipe .equipe-box .atuacao {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.equipe .equipe-box .atuacao h2 {
  margin-right: 2rem;
}

.equipe .equipe-box .social div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.equipe .equipe-box .social h2 {
  margin-right: 1rem;
}

.equipe .equipe-box .title {
  display: flex;
  align-items: baseline;
}

.equipe .equipe-box .title h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #272C2E;
  margin: 0 1rem 1rem 0;
}

.equipe .equipe-box .title h3 {
  font-size: 1.4rem;
  color: #75594C;
  text-transform: uppercase;
}

.equipe .equipe-box .content {
  color: #084054;
}

.equipe .equipe-header {
  position: relative;
  padding: 15px 0;
  border-bottom: 1px solid #e6e6e6;
}

.equipe .equipe-header .equipe-title {
  padding: 0 40px 0 15px;
  font-size: 22px;
  font-weight: 700;
}

.equipe .equipe-content {
  padding: 15px;
  font-size: 16px;
  overflow-y: auto;
}

.equipe .equipe-close {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  background: none;
}

.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.no-js .owl-carousel {
  display: block;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
  -moz-transition: scale(1.3, 1.3);
  -ms-transition: scale(1.3, 1.3);
  -o-transition: scale(1.3, 1.3);
  transition: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

a[data-fancybox] img {
  cursor: zoom-in;
}

.fancybox__backdrop::after {
  content: "";
  position: absolute;
  width: 10%;
  height: 10%;
  filter: blur(2px);
  left: 50%;
  top: 50%;
  transform: scale(11);
  opacity: 0.3;
  background-image: var(--bg-image);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.fancybox__container {
  --fancybox-bg: #000;
  --fancybox-thumbs-width: 48px;
  --fancybox-thumbs-ratio: 1;
  --carousel-button-svg-width: 24px;
  --carousel-button-svg-height: 24px;
  --carousel-button-svg-stroke-width: 2.5;
}

.fancybox__nav {
  --carousel-button-svg-width: 24px;
  --carousel-button-svg-height: 24px;
}

.fancybox__nav .carousel__button.is-prev {
  left: 20px;
}

.fancybox__nav .carousel__button.is-next {
  right: 20px;
}

.carousel__button.is-close {
  right: auto;
  top: 20px;
  left: 20px;
}

.fancybox__slide {
  padding: 8px 88px;
}

.fancybox__thumbs .carousel__slide {
  padding: 8px 8px 16px 8px;
}

.is-nav-selected::after {
  display: none;
}

.fancybox__thumb {
  border-radius: 6px;
  opacity: 0.4;
}

.fancybox__thumb:hover,
.is-nav-selected .fancybox__thumb {
  border-radius: 6px;
  opacity: 1;
}

.is-nav-selected .fancybox__thumb::after {
  display: none;
}

.fancybox__backdrop {
  background-color: rgba(0,0,0,0.8) !important;
}

.lb-loader,
.lightbox {
  text-align: center;
  line-height: 0;
  position: absolute;
  left: 0;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #000;
  filter: alpha(Opacity=80);
  opacity: .8;
  display: none;
}

.lightbox {
  width: 100%;
  z-index: 10000;
  font-weight: 400;
  outline: 0;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  border: 4px solid #fff;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: #fff;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  top: 43%;
  height: 25%;
  width: 100%;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../img/fancybox/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container>.nav {
  left: 0;
}

.lb-nav a {
  outline: 0;
  background-image: url(data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==);
}

.lb-next,
.lb-prev {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../img/fancybox/prev.png) left 48% no-repeat;
  filter: alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  -o-transition: opacity .6s;
  transition: opacity .6s;
}

.lb-nav a.lb-prev:hover {
  filter: alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../img/fancybox/next.png) right 48% no-repeat;
  filter: alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  -o-transition: opacity .6s;
  transition: opacity .6s;
}

.lb-nav a.lb-next:hover {
  filter: alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: 700;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../img/fancybox/close.png) top right no-repeat;
  text-align: right;
  outline: 0;
  filter: alpha(Opacity=70);
  opacity: .7;
  -webkit-transition: opacity .2s;
  -moz-transition: opacity .2s;
  -o-transition: opacity .2s;
  transition: opacity .2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: alpha(Opacity=100);
  opacity: 1;
}

@media screen and (min-width: 769px) {
  .form-newsletter input {
    border-radius: 6px;
  }

  .form-newsletter button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 35px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .card-post .card-post-content .card-post-title {
    height: 70px;
    overflow: hidden;
  }

  .card-post .card-post-content .card-post-excerpt {
    height: 90px;
    overflow: hidden;
  }

  .product-item .product-item-content .product-item-title {
    overflow: hidden;
  }
}

@media (min-width: 769px) {
  .container {
    width: 750px;
  }

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }

  .col-sm-1 {
    width: 8.33333%;
  }

  .col-sm-2 {
    width: 16.66667%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.33333%;
  }

  .col-sm-5 {
    width: 41.66667%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.33333%;
  }

  .col-sm-8 {
    width: 66.66667%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.33333%;
  }

  .col-sm-11 {
    width: 91.66667%;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-pull-1 {
    right: 8.33333%;
  }

  .col-sm-pull-2 {
    right: 16.66667%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-4 {
    right: 33.33333%;
  }

  .col-sm-pull-5 {
    right: 41.66667%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-7 {
    right: 58.33333%;
  }

  .col-sm-pull-8 {
    right: 66.66667%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-10 {
    right: 83.33333%;
  }

  .col-sm-pull-11 {
    right: 91.66667%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-push-1 {
    left: 8.33333%;
  }

  .col-sm-push-2 {
    left: 16.66667%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-4 {
    left: 33.33333%;
  }

  .col-sm-push-5 {
    left: 41.66667%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-7 {
    left: 58.33333%;
  }

  .col-sm-push-8 {
    left: 66.66667%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-10 {
    left: 83.33333%;
  }

  .col-sm-push-11 {
    left: 91.66667%;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media screen and (min-width: 992px) {
  .pd-5-d {
    padding-right: 5rem;
  }

  .card-row-box {
    width: 23% !important;
  }

  .card-equipe-row-box {
    width: 31% !important;
  }

  .card-atuacao-row-box .content {
    min-height: 32rem;
  }

  .card-atuacao-row-box {
    width: 31% !important;
  }

  .section-missao figure img {
    position: absolute;
    margin-top: -12rem;
  }

  .section-highlight img {
    width: 100vw;
  }
}

@media (min-width: 993px) {
  .container {
    width: 970px;
  }

  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }

  .col-md-1 {
    width: 8.33333%;
  }

  .col-md-2 {
    width: 16.66667%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.33333%;
  }

  .col-md-5 {
    width: 41.66667%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.33333%;
  }

  .col-md-8 {
    width: 66.66667%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.33333%;
  }

  .col-md-11 {
    width: 91.66667%;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-pull-1 {
    right: 8.33333%;
  }

  .col-md-pull-2 {
    right: 16.66667%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-4 {
    right: 33.33333%;
  }

  .col-md-pull-5 {
    right: 41.66667%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-7 {
    right: 58.33333%;
  }

  .col-md-pull-8 {
    right: 66.66667%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-10 {
    right: 83.33333%;
  }

  .col-md-pull-11 {
    right: 91.66667%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-push-1 {
    left: 8.33333%;
  }

  .col-md-push-2 {
    left: 16.66667%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-4 {
    left: 33.33333%;
  }

  .col-md-push-5 {
    left: 41.66667%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-7 {
    left: 58.33333%;
  }

  .col-md-push-8 {
    left: 66.66667%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-10 {
    left: 83.33333%;
  }

  .col-md-push-11 {
    left: 91.66667%;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333%;
  }

  .col-md-offset-2 {
    margin-left: 16.66667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333%;
  }

  .col-md-offset-5 {
    margin-left: 41.66667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333%;
  }

  .col-md-offset-8 {
    margin-left: 66.66667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333%;
  }

  .col-md-offset-11 {
    margin-left: 91.66667%;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media screen and (min-width: 993px) {
  .list-credits {
    text-align: right;
  }

  .navigation .menu {
    display: block !important;
  }

  .navigation .menu .menu-item:hover .menu-item-dropdown,
  .navigation .menu .menu-item:focus .menu-item-dropdown {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .btn-hamburguer {
    display: none;
  }

  .footer .footer-information .social-network {
    padding-left: 10rem;
  }
}

@media (min-width: 1201px) {
  .container {
    width: 1170px;
  }

  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }

  .col-lg-1 {
    width: 8.33333%;
  }

  .col-lg-2 {
    width: 16.66667%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-4 {
    width: 33.33333%;
  }

  .col-lg-5 {
    width: 41.66667%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-7 {
    width: 58.33333%;
  }

  .col-lg-8 {
    width: 66.66667%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-10 {
    width: 83.33333%;
  }

  .col-lg-11 {
    width: 91.66667%;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-pull-1 {
    right: 8.33333%;
  }

  .col-lg-pull-2 {
    right: 16.66667%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-4 {
    right: 33.33333%;
  }

  .col-lg-pull-5 {
    right: 41.66667%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-7 {
    right: 58.33333%;
  }

  .col-lg-pull-8 {
    right: 66.66667%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-10 {
    right: 83.33333%;
  }

  .col-lg-pull-11 {
    right: 91.66667%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-push-1 {
    left: 8.33333%;
  }

  .col-lg-push-2 {
    left: 16.66667%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-4 {
    left: 33.33333%;
  }

  .col-lg-push-5 {
    left: 41.66667%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-7 {
    left: 58.33333%;
  }

  .col-lg-push-8 {
    left: 66.66667%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-10 {
    left: 83.33333%;
  }

  .col-lg-push-11 {
    left: 91.66667%;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

@media screen and (max-width: 1600px) {
  .hero-slider-nav .hero-slider-navigation.to-left {
    left: 15px;
  }

  .hero-slider-nav .hero-slider-navigation.to-right {
    right: 15px;
  }
}

@media screen and (max-width: 1400px) {
  .hero-slider-stage .content {
    max-width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 1200px) {
  .hero-slider-stage p {
    font-size: 30px;
    line-height: 34px;
  }

  .hero-slider-nav {
    display: none;
  }

  .navigation .menu .menu-item>a {
    padding: 8px 20px;
  }
}

@media screen and (max-width: 992px) {
  .modalequipe .modalequipe-box .atuacao {
    flex-direction: column;
  }

  .modalequipe .modalequipe-box .atuacao div {
    margin: 1rem 0;
  }

  .modalequipe .modalequipe-box .social div {
    flex-direction: column;
    margin: 1rem 0;
  }

  .modalequipe .modalequipe-box .title h2 {
    margin: 2rem 1rem 5px 0;
  }

  .modalequipe .modalequipe-box .title h3 {
    margin: 0 1rem 2rem 0;
  }

  .modalequipe .modalequipe-box .title {
    flex-direction: column;
  }

  .modalequipe .modalequipe-box {
    max-width: 100%;
    padding: 2rem 2rem;
    overflow-y: scroll;
  }

  .modalatuacao .modalatuacao-box .advogados-row {
    margin-left: 0;
  }

  .modalatuacao .modalatuacao-box {
    max-width: 100%;
    padding: 2rem 2rem;
  }

  .modalatuacao .modalatuacao-close {
    top: 100%;
    right: 10px;
    transform: translateY(-100%);
    font-size: 2rem;
  }

  .title-border {
    font-size: 34px;
  }

  .hero-slider {
    padding-top: 10rem;
    background: linear-gradient(0deg, #000 0%, #002F40 100%);
  }

  .hero-slider-stage {
    height: 345px;
  }

  .hero-slider-stage video {
    width: 150%;
  }

  .hero-slider-bullets {
    bottom: 30px;
  }

  .card-visual .card-visual-image img {
    width: 100%;
    opacity: 0.7;
  }

  .btn.btn-lg {
    padding: 9px 12px;
    font-size: 1.5rem;
  }

  .btn.btn-md {
    width: 23.5rem;
  }

  .btn i {
    margin-left: 1rem;
  }

  .page-header .container {
    flex-direction: column;
  }

  .page-header {
    padding: 15rem 0 5rem;
  }

  .carousel-product-home .owl-controls img {
    width: 75%;
  }

  .carousel-product-home .owl-prev {
    left: -2%;
    top: 35%;
  }

  .carousel-product-home .owl-next {
    top: 35%;
  }

  .navigation {
    float: initial;
    margin: 0;
  }

  .navigation .menu {
    top: 100%;
    left: 0;
    width: 95vw;
    display: none;
    background: #25657C;
    z-index: 100;
  }

  .navigation .menu .menu-item {
    width: 100%;
    display: block;
  }

  .navigation .menu .menu-item>a {
    padding: 20px 5px;
  }

  .navigation .menu .menu-item>a:hover,
  .navigation .menu .menu-item>a:focus {
    padding: 20px 5px;
  }

  .footer .footer-information .footer-heading.map:before {
    margin-left: 0px;
  }

  .footer .footer-information .footer-heading.onde:before {
    margin-left: 0px;
  }

  .footer .footer-copyright {
    text-align: center;
  }

  .footer .footer-copyright .footer-copyright-disclaimer {
    padding: 0 0 25px 0;
  }

  .section-default.featured p {
    font-size: 3rem;
  }

  .section-default.featured {
    margin-top: -9rem;
    padding: 15px 0 25px;
  }

  .section-default.featured img {
    width: 65%;
  }

  .section-historia .title-highlight::before {
    width: 0;
    margin-left: 0;
  }

  .section-historia .title-highlight {
    padding: 1rem 0;
  }

  .section-historia .title-highlight>div {
    padding: 1rem 1rem 0 0;
  }

  .section-historia .content {
    padding: 1rem 0;
  }

  .section-historia {
    padding: 5rem 0 8rem;
  }

  .section-missao .title-highlight {
    padding: 1rem 0;
  }

  .section-missao .title-highlight>div {
    padding: 1rem 1rem 0 0;
  }

  .section-missao .content {
    padding: 1rem 0;
  }

  .section-missao {
    padding: 5rem 0 8rem;
  }

  .section-estrutura-equipe .btn {
    margin-top: 2rem;
    width: 25rem;
  }

  .section-estrutura-equipe figure::before {
    width: 0;
    margin-left: 0;
  }

  .section-estrutura-equipe figure::after {
    width: 0;
    margin-left: 0;
  }

  .section-estrutura-nossa .title-highlight-retangulo {
    margin-left: 1rem;
  }

  .section-estrutura-nossa .title-highlight::before {
    width: 0;
    margin-left: 0;
  }

  .section-estrutura-nossa .title-highlight {
    padding: 1rem 0;
  }

  .section-estrutura-advogado div {
    padding: 0;
  }

  .section-estrutura-advogado .hr-section :before {
    width: 4rem;
    height: 3px;
    margin-right: 2rem;
  }

  .section-estrutura-advogado .hr-section :after {
    width: 4rem;
    height: 3px;
    margin-left: 2rem;
  }

  .section-estrutura-advogado h2 {
    font-size: 1.45rem;
    margin: 0 0 4rem;
  }

  .section-estrutura .container-mosaico {
    height: 375px;
    gap: 10px;
    grid-template-columns: 105px 105px 105px;
    grid-template-rows: 80px 80px 80px 80px;
  }

  .section-highlight {
    width: 200%;
    margin-left: -50%;
  }

  .newsletter .newsletter-text {
    margin-bottom: 20px;
    text-align: center;
  }

  .product-item {
    width: 80%;
    margin-left: 10%;
  }

  .product-item .product-item-image {
    height: 500px;
  }

  .product-item .product-item-content-inner {
    margin-top: -1rem;
  }

  .contact .content h2 i {
    width: 3rem;
  }

  .contact .content h2 {
    font-size: 2.25rem;
  }

  .contact .content h3 a {
    font-size: 1.5rem;
  }

  .contact .content h3 {
    margin-left: 5rem;
  }

  .contact .content::before {
    width: 0;
    margin-left: 0;
  }

  .row-selos figure {
    height: initial;
    margin-bottom: 2rem;
  }

  .row-selos div {
    width: 100%;
    margin: 2rem 1.5%;
  }

  .row-icons div {
    width: 35%;
    margin: 1rem 2rem;
  }

  .half-left {
    width: 100%;
  }

  .half-left iframe {
    width: 100%;
  }

  .half-right {
    width: 100%;
  }

  .list-clientes li {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .form-search {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    float: none;
    margin-bottom: 1rem;
  }

  .form-search.filter {
    max-width: 100%;
  }

  .form-newsletter button {
    display: block;
    width: 100%;
    padding: 10px;
  }

  .title-default {
    font-size: 28px;
    line-height: 34px;
  }

  .title-product-home {
    font-size: 20px;
    width: 28rem;
  }

  .title-product-home::after {
    right: -38px;
    border-left: 19px solid #f6323e;
    border-top: 19px solid #f6323e;
    border-right: 19px solid transparent;
    border-bottom: 19px solid transparent;
  }

  .hero-slider-stage .content {
    transform: translateY(-75%);
  }

  .hero-slider-stage p {
    font-size: 22px;
    line-height: 24px;
  }

  .card-visual {
    margin-left: 5rem;
  }

  .card-visual>a {
    width: 80%;
  }

  .card-visual .card-visual-content {
    padding: 0px 20px 20px 20px;
    margin-top: -20px;
  }

  .carousel-partners .owl-item img {
    max-width: 60%;
    display: block;
    margin: 0 auto;
  }

  .google-maps {
    height: 280px;
  }

  .logo {
    width: 85%;
    padding: 30px 0 15px;
    text-align: center;
  }

  .d-flex {
    display: flex;
  }

  .header-information {
    float: none;
    text-align: center;
    margin-bottom: 25px;
  }

  .header-information strong {
    padding: 15px 0 30px;
    float: none;
  }

  .header-information .header-information-text {
    float: none;
    text-align: center;
  }

  .section-default {
    overflow: hidden;
  }

  .about .about-title span {
    text-align: center;
  }

  .about .about-image img {
    float: inherit;
  }

  .product-item .product-item-image img {
    top: 30%;
  }

  .product-item .product-item-content {
    margin-top: -9rem;
    padding: 5px 1.5rem;
  }

  .post-view .post-view-head .post-view-head-title {
    font-size: 22px;
    line-height: 26px;
  }

  .post-view .post-view-head .post-view-head-meta span {
    display: block;
  }

  .contact-information .contact-information-item {
    max-width: 250px;
    width: 100%;
    margin: 0 auto;
  }

  .md-25 {
    width: auto !important;
  }
}

@media screen and (max-width: 480px) {
  .title-default {
    font-size: 22px;
    line-height: 26px;
  }

  .hero-slider-stage p {
    font-size: 18px;
    line-height: 24px;
  }

  .product-item .product-item-content {
    margin-top: -4rem;
    padding: 5px 1.5rem;
  }
}